<template>
  <vx-card style="padding-left: 10px" :key="rerenderKey">
    <div id="data-list-list-view" class="data-list-container">
      <ul>
        <li>
          <vs-button
            id="button-with-loading"
            class="vs-con-loading__container float-right mb-5"
            align="right"
            @click="createQuestion"
            icon-pack="feather"
            icon="icon-edit"
            >Create question</vs-button
          >
          <a
            id="downloadCustomerCsv"
            ref="downloadCustomerCSVRef"
            href="/create"
          ></a>
        </li>
      </ul>

      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="questions"
        @change-page="handleChangePage"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
        class="mt-5"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow">
          <vs-select
            placeholder="All Status"
            class="mr-6"
            v-model="selectedStatus"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item.text"
              v-for="(item, index) in statusFilter"
            />
          </vs-select>
          <vs-select
            placeholder="All Mandatory"
            class="mr-6"
            v-model="selectedMandatory"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item.text"
              v-for="(item, index) in mandatoryFilter"
            />
          </vs-select>
          <vs-select
            placeholder="All Treatment types"
            class="mr-6"
            v-model="selectedTreatmentType"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item.text"
              v-for="(item, index) in treatmentTypeFilter"
            />
          </vs-select>
        </div>

        <template>
          <vs-th sort-key="clinicName">Position</vs-th>
          <vs-th sort-key="firstName">Status</vs-th>
          <vs-th sort-key="lastName">Question</vs-th>
          <vs-th sort-key="email">Mandatory</vs-th>
          <vs-th sort-key="status">Treatment Type</vs-th>
          <vs-th sort-key="hasApprovedByAdmin">action</vs-th>

          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in questions"
            class="opacity-50"
          >
            <vs-td :data="questions[indextr].order">
              <span class="flex-row flex">
                <p class="self-end">{{ (indextr + 1) | capitalize }}</p>
                <feather-icon class="cursor-pointer2 ml-3" icon="MenuIcon" />
              </span>
            </vs-td>
            <vs-td :data="questions[indextr].status">
              <vs-switch
                disabled="true"
                vs-value="true"
                style="margin-right: 20px"
                v-model="defaultQuestionStatus"
                >Yes</vs-switch
              >
            </vs-td>
            <vs-td :data="questions[indextr].question">{{
              questions[indextr].question | capitalize
            }}</vs-td>
            <vs-td :data="questions[indextr].is_mandatory">Yes</vs-td>
            <vs-td :data="questions[indextr].treatments"></vs-td>
            <vs-td :data="questions[indextr]._id"></vs-td>
          </vs-tr>

          <draggable
            :list="customQuestions"
            tag="tbody"
            @update="onDragEnd(customQuestions)"
          >
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in customQuestions"
            >
              <vs-td :data="customQuestions[indextr].order">
                <span class="flex-row flex">
                  <p>{{ (defaultQuestions + indextr + 1) | capitalize }}</p>
                  <feather-icon class="cursor-pointer2 ml-3" icon="MenuIcon" />
                </span>
              </vs-td>
              <vs-td :data="customQuestions[indextr].status">
                <vs-switch
                  vs-value="customQuestions[indextr]._id"
                  style="margin-right: 20px"
                  v-model="customQuestions[indextr].status"
                  @change="checkChange(customQuestions[indextr])"
                  >Yes</vs-switch
                >
              </vs-td>
              <vs-td :data="customQuestions[indextr].question">{{
                customQuestions[indextr].question | capitalize
              }}</vs-td>
              <vs-td :data="customQuestions[indextr].is_mandatory">{{
                customQuestions[indextr].is_mandatory
                  ? "Yes"
                  : "No" | capitalize
              }}</vs-td>
              <vs-td :data="customQuestions[indextr].treatments">
                <span
                  v-for="item in customQuestions[indextr].treatments"
                  :key="item.treatmentId"
                >
                  {{ item.treatmentName | capitalize }},
                </span>
              </vs-td>
              <vs-td :data="customQuestions[indextr]._id">
                <span
                  class="font-bold"
                  @click="updateCustomQuestion(customQuestions[indextr])"
                  >Edit</span
                >
              </vs-td>
            </vs-tr>
          </draggable>
        </template>
      </vs-table>
      <div
        class="
          m-2
          flex
          sm:justify-between
          justify-center
          items-center
          flex-wrap
        "
        style="height: 35px padding-bottom: 30px"
      >
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="5"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import pairDevice from "../../components/others/addPairDevice";
import pairDeviceList from "../../components/others/pairDeviceList";
import UnassociateClinic from "../../components/others/UnassociateClinic";
import draggable from "vuedraggable";
import vSelect from "vue-select";

export default {
  components: {
    pairDevice,
    "view-pair-device": pairDeviceList,
    "unassociate-clinic": UnassociateClinic,
    draggable,
    "v-select": vSelect,
  },
  props: {},
  data() {
    return {
      noDataText: "Loading...",
      rerenderKey: 0,
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        userType: "clinic",
        status: "All",
      },
      selectedStatus: "All",
      selectedMandatory: "All",
      selectedTreatmentType: "All",
      serverResponded: false,
      questions: [],
      customQuestions: [],
      tempQuestions: [],
      tempCustomQuestions: [],
      defaultQuestions: 0,
      defaultQuestionStatus: true,
      date: new Date(),
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      awaitingSearch: null,
      mandatoryFilter: [
        { value: "All", text: "All Mandatory" },
        { value: true, text: "Mandatory" },
        { value: false, text: "Not Mandatory" },
      ],
      statusFilter: [
        { value: "All", text: "All Status" },
        { value: false, text: "Off" },
        { value: true, text: "On" },
      ],
      treatmentTypeFilter: [{ value: "All", text: "All Treatment types" }],
    };
  },
  methods: {
    ...mapActions("clinic", [
      "fetchCustomConsentList",
      "changeCustomConsentOrders",
      "fetchDefaultConsentList",
    ]),
    checkChange(props) {
      this.changeCustomConsentOrders({ ...props });
    },
    updateCustomQuestion(item) {
      this.$router.push({
        name: "createCustomConsent",
        params: { editData: { ...item } },
      });
    },
    onDragEnd(props) {
      props.map((item, index) => {
        this.changeCustomConsentOrders({ ...item, order: parseInt(index) + 1 });
      });
    },
    getClinicsList() {
      let self = this;
      const user = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      );
      this.fetchDefaultConsentList(user.id).then((res) => {
        const questions = res.data.data.docs;
        const filtered = questions.filter((item) => {
          return item.isEnabled;
        });
        this.defaultQuestions = filtered.length;
        this.questions = filtered;
        this.tempQuestions = filtered;
      });
      this.fetchCustomConsentList(user.id).then((res) => {
        const questions = res.data.data;
        const custom = questions.sort((a, b) => a.order - b.order);
        this.customQuestions = custom;
        this.tempCustomQuestions = custom;
        this.totalDocs = this.questions.length + this.customQuestions.length;
        custom.map((questions) => {
          questions.treatments.map((treatment) => {
            if (
              !this.treatmentTypeFilter.some(
                (item) => item.text === treatment.treatmentName
              )
            ) {
              this.treatmentTypeFilter.push({
                value: treatment.treatmentName,
                text: treatment.treatmentName,
              });
            }
          });
        });
      });
    },
    createQuestion() {
      this.$router.push({ name: "createCustomConsent" });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getClinicsList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getClinicsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getClinicsList();
    },
  },
  watch: {
    rerenderKey: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getClinicsList();
      }
    },
    selectedStatus: function (newVal, oldVal) {
      if (newVal.value === true) {
        this.questions = this.tempQuestions;
        this.customQuestions = this.customQuestions.filter(
          (item) => item.status === true
        );
        this.totalDocs = this.questions.length + this.customQuestions.length;
      } else if (newVal.value === false) {
        this.questions = [];
        this.customQuestions = this.customQuestions.filter(
          (item) => item.status === false
        );
        this.totalDocs = this.questions.length + this.customQuestions.length;
      } else if (newVal.value === "All") {
        this.questions = this.tempQuestions;
        this.customQuestions = this.tempCustomQuestions;
        this.totalDocs = this.questions.length + this.customQuestions.length;
      }
    },
    selectedMandatory: function (newVal, oldVal) {
      if (newVal.value === true) {
        this.questions = this.tempQuestions;
        this.customQuestions = this.customQuestions.filter(
          (item) => item.is_mandatory === true
        );
        this.totalDocs = this.questions.length + this.customQuestions.length;
      } else if (newVal.value === false) {
        this.questions = [];
        this.customQuestions = this.customQuestions.filter(
          (item) => item.is_mandatory === false
        );
        this.totalDocs = this.questions.length + this.customQuestions.length;
      } else if (newVal.value === "All") {
        this.questions = this.tempQuestions;
        this.customQuestions = this.tempCustomQuestions;
        this.totalDocs = this.questions.length + this.customQuestions.length;
      }
    },
    selectedTreatmentType: function (newVal, oldVal) {
      if (newVal.value === "All") {
        this.questions = this.tempQuestions;
        this.customQuestions = this.tempCustomQuestions;
      } else {
        const customQuestions = [];
        this.customQuestions.map((question) => {
          if (
            question.treatments.some(
              (item) => item.treatmentName === newVal.text
            )
          ) {
            customQuestions.push(question);
          }
        });
        this.questions = [];
        this.customQuestions = customQuestions;
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  created() {
    this.getClinicsList();
  },
};
</script>
